<script>
import newMessageSound from "./new_message.mp3";

export default {
  data() {
    return {
      counter: 0,
      messages: [null, null, null, null],
      imgSrc: null,
    };
  },

  methods: {
    close(id) {
      delete this.messages[
        this.messages.findIndex((message) => message?.id === id)
      ];
    },
  },

  created() {
    const sound = new Audio(newMessageSound);
    this.imgSrc = this.$server;
    this.socket.on("server message", (data) => {
      const freePlace = this.messages.findIndex((message) => !message);

      sound.currentTime = 0;
      sound.volume = 1;
      sound.play();

      if (freePlace === -1) return;

      this.messages[freePlace] = {
        id: freePlace,
        name: data.client.name,
        message: data.text,
        avatar: data.client.avatar,
      };

      setTimeout(() => {
        this.close(freePlace);
        this.counter--;
      }, 5000);
    });
  },
};
</script>

<template>
  <template v-for="message of messages">
    <div
      class="container"
      v-if="message"
      :key="message.id"
      :style="{ top: 100 * message.id + 40 + 'px' }"
    >
      <img
        v-if="message.avatar"
        :src="imgSrc + '/' + message.avatar"
        alt=""
        class="photo"
      />
      <img v-else src="../../assets/icons/chat.svg" alt="" class="photo" />
      <div class="inner">
        <div class="name">{{ message.name }}</div>
        <div class="message">
          {{ message.message }}
        </div>
      </div>
      <img
        src="../../assets/icons/xWhite.svg"
        alt=""
        class="close"
        @click="() => close(message.id)"
      />
    </div>
  </template>
</template>

<style scoped>
.container {
  position: absolute;
  top: 50px;
  right: 50px;
  display: flex;
  width: 400px;
  height: 80px;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  color: white;
}
.photo {
  width: 60px;
  height: 60px;
  margin: 10px;
}
.inner {
  height: 50px;
  width: 270px;
  margin-top: 10px;
  margin-left: 10px;
}
.name {
  font-size: 20px;
  height: 25px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.message {
  height: 25px;
  margin-top: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.close {
  position: absolute;
  height: 22px;
  width: 22px;
  top: 5px;
  right: 8px;
}
</style>
